
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { priceRulesEl } from "@/utils/verify";
import { emptyToLine, splitThousands } from "@/utils/common";

const base = namespace("base");
const marketing = namespace("marketing");
const setting = namespace("setting");
const app = namespace("app");

@Component({})
export default class ReturnCard extends Vue {
  @base.Action getDictionaryList;
  @marketing.Action queryCardDetail;
  @setting.Action getPatternPaymentList;
  @marketing.Action updateCardStatusRefund;
  @app.State btnLoading;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;

  groupIndex: number = 1;
  detailData: any = {
    operateList: []
  };
  payWayList: any = [];
  showRefundModal: boolean = false;
  recommendReturnAmount: string = "";
  @Prop({ type: String })
  cardCode;
  get orderId() {
    return this.$route.params.id || this.cardCode;
  }
  refundForm: any = {
    cardCode: "",
    returnAmount: "",
    returnMethod: "",
    returnRemark: ""
  };
  checkPrice(rule, value, callback) {
    priceRulesEl(value, callback);
  }
  refundFormRule: any = {
    returnAmount: [
      { required: true, message: "请输入实际退款金额", trigger: "blur" },
      { validator: this.checkPrice, trigger: "blur" }
    ],
    returnRemark: { required: true, message: "请输入退款原因", trigger: "blur" },
    returnMethod: { required: true, message: "请选择支付方式", trigger: "change" }
  };
  created() {
    // if (this.orderId) {
    //   this.init();
    // }
  }
  showRefundModalFn() {
    this.showRefundModal = true;
    this.refundForm.returnMethod = "";
    this.refundForm.returnRemark = "";
    this.$nextTick(() => {
      (this.$refs.refundForm as any).clearValidate();
    });
    this.init();
  }
  actualReturnAmount: number = 0;
  init() {
    this.btnStartLoading();
    this.queryCardDetail({
      cardCode: this.orderId
    })
      .then(data => {
        data = data.data || {};
        if (data.cardType === 1) {
          data.serviceList &&
            data.serviceList.forEach(serviceItem => {
              if (serviceItem.partsList && serviceItem.partsList.length > 0) {
                serviceItem.isGroup = true;
                serviceItem.groupServiceIndex = this.groupIndex;
                serviceItem.partsList.forEach(partsItem => {
                  data.partsList.push(
                    Object.assign(partsItem, {
                      isGroup: true,
                      groupServiceIndex: this.groupIndex
                    })
                  );
                });
                this.groupIndex++;
              }
            });
        }
        this.detailData = data;
      })
      .finally(() => {
        this.btnStopLoading();
      });
    this.getPatternPaymentList({
      status: "1"
    }).then(data => {
      this.payWayList = this.$safeValue(data, "data", []);
    });
  }
  handleconfirmRefund() {
    (this.$refs.refundForm as any).validate(valid => {
      if (valid) {
        this.btnStartLoading();
        this.refundForm.cardCode = this.orderId;
        this.updateCardStatusRefund(this.refundForm)
          .then(data => {
            this.$message.success("退卡成功");
            this.$emit("returnCardSuccess");
          })
          .finally(item => {
            this.showRefundModal = false;
            this.btnStopLoading();
          });
      }
    });
  }
}
